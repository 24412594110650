export default class {
    constructor(param) {
        this.type = param.type;// default or movie
        this.triggerOpenClass = param.triggerOpenClass;
        this.triggerCloseClass = param.triggerCloseClass;
        this.modalTargetID = param.modalTargetID;
        this.movieID = param.movieID;

        // callback
        this.openingEvent = param.openingEvent;
        this.openedEvent = param.openedEvent;
        this.closingEvent = param.closingEvent;
        this.closedEvent = param.closedEvent;
    }
    init(){
        this.setContents();
        this.setEvent();
    }
    setContents(){
        if (this.triggerOpenClass) {
            this.$triggerOpen = $('.' + this.triggerOpenClass);
        };
        if (this.triggerCloseClass) {
            this.$triggerClose = $('.' + this.triggerCloseClass);
        };

        this.$target = $('#' + this.modalTargetID);

        this.inst = this.$target.remodal();// remodalのインスタンスを生成
    }
    setEvent(){
        $(document).on('click', '.' + this.triggerOpenClass, (e)=>{
            this.inst.open();
            return false;
        });
        $(document).on('click', '.' + this.triggerCloseClass, (e)=>{
            this.inst.close();
            return false;
        });

        $(document).on('opening', '#' + this.modalTargetID, (e)=>{
            this.opening(e);
        });

        $(document).on('opened', '#' + this.modalTargetID, (e)=>{
            this.opened(e);
        });

        $(document).on('closing', '#' + this.modalTargetID, (e)=>{
            this.closing(e);
        });

        $(document).on('closed', '#' + this.modalTargetID, (e)=>{
            this.closed(e);
        });
    }
    openModal(){
        this.inst.open();
    }
    closeModal(){
        this.inst.close();
    }
    opening(){
        if (this.type == 'movie') {
            this.$target.append(
                '<div class="movieModal_iframeWrap">'+
                    '<iframe src="https://www.youtube.com/embed/' + this.movieID + '?rel=0&autoplay=1" frameborder="0" allowfullscreen></iframe>'+
                '</div>'
            );
        }

        if (this.openingEvent) {
            this.openingEvent();
        };
    }
    opened(e){
        if (!this.openedEvent) { return };

        this.openedEvent();
    }
    closing(e){
        if (!this.closingEvent) { return };

        this.closingEvent();
    }
    closed(e){
        if (this.type == 'movie') {
            this.$target.find('.movieModal_iframeWrap').remove();
        }

        if (!this.closedEvent) { return };

        this.closedEvent();
    }
}
