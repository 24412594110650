export default class {
    init(){
        this.setEvent();
    }
    setEvent(){
        $(window).on('load',function(){
            $("#splash-logo").delay(1000).fadeOut('slow');
            $("#splash").delay(1000).fadeOut('slow',function(){
                $('body').addClass('appear');
                $('#app').addClass('is-show');
            });
            $('.splashbg').on('animationend', function() {    
                
            });
        });
    }
}