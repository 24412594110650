export default class {
    constructor() {
        this.$el = $('.footer_pageTop');

        this.point1 = 200;
        this.flag1;
        this.flag2;
    }
    init(){
        if (this.$el.length == 0) { return };

        this.setEvent();
    }
    initPosition(){
        this.setData();
        this.togglePosition();
        this.setPosition();
    }
    setEvent(){
        $(window).on('load scroll resize', ()=>{
            this.initPosition();
        });
    }
    setData(){
        this.scrollTop = $(window).scrollTop();
        this.bodyH = $(window).scrollTop();
        this.footerTop = $('.footer').offset().top;
        this.fitPoint = this.footerTop - app.size.winH;
    }
    togglePosition(){
        let show = ()=>{
            TweenMax.to(this.$el, .5, {
                ease: Power3.easeOut,
                'transform': 'translateY(0)',
                onComplete: ()=>{
                    this.$el.css({ 'opacity': 1 });
                }
            });
        };
        let hide = ()=>{
            TweenMax.to(this.$el, .5, {
                ease: Power3.easeIn,
                'transform': 'translateY(200px)',
                onComplete: ()=>{
                    this.$el.css({ 'opacity': 1 });
                }
            });
        };

        if (this.scrollTop > this.point1 && this.flag1 != 'show') {
            this.flag1 = 'show';
            show();
        } else if (this.scrollTop <= this.point1 && this.flag1 != 'hide') {
            this.flag1 = 'hide';
            hide();
        }
    }
    setPosition(){
        let fit = ()=>{
            this.$el.removeClass('-fixed');
        };
        let move = ()=>{
            this.$el.addClass('-fixed');
        };

        if (this.scrollTop > this.fitPoint && this.flag2 != 'fit') {
            this.flag2 = 'fit';
            fit();
        } else if (this.scrollTop <= this.fitPoint && this.flag2 != 'move') {
            this.flag2 = 'move';
            move();
        }
    }
}
