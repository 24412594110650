export default class {
    init(){
        this.setEvent();
    }
    setEvent(){
        window.addEventListener('scroll', () => {
            const element = document.querySelector('.scroll');
            const scrollPosition = window.scrollY || window.pageYOffset;
            if (scrollPosition > 100) {
                element.classList.add('is-active');
            } else {
                element.classList.remove('is-active');
            }
        });
    }
}
