export default class {
    constructor() {
        this.$panelList = $('.panelListPickup');
        this.$dist = $('#tmpl_panelListPickup');

        this.category_list = ['pickup'];
        this.data_array = [];

        this.category;

        this.data = [];

        this.SHOW_ITEMS = 3;
    }
    init(){
        if (this.$panelList.length == 0) { return };

        this.setJson();
    }
    setJson(){
        let judgeCategory = ()=>{
            let data = this.$panelList.attr('data-category');

            if (data == 'panel-all') {
                this.category = 'all';
            } else if (data == 'panel-data') {
                this.category = 'data';
            } else if (data == 'panel-special') {
                this.category = 'special';
            } else if (data == 'panel-workshop') {
                this.category = 'workshop';
            } else if (data == 'panel-event') {
                this.category = 'event';
            } else if (data == 'panel-pickup') {
                this.category = 'pickup';
            } else if (data == 'panel-other') {
                this.category = 'other';
            }
        };

        let sortData = ()=>{
            let fixData;

            if (this.category == 'all') {
                for (var i = 0; i < this.data_array.length; i++) {
                    for (var j = 0; j < this.data_array[i].length; j++) {
                        this.data.push(this.data_array[i][j]);
                    }
                }

                this.data.sort((a,b)=>{
                    return (a.date < b.date ? 1 : -1);
                });
            } else {
                this.data.reverse();
            }

            //表示すべきデータをセットしてからイベントスタート
            //this.setEvent();
        };

        let setJson = ()=>{
            if (this.category == 'all') {
                for (var i = 0; i < this.category_list.length; i++) {
                    $.ajax({
                        type: 'GET',
                        url: '/assets/json/list_' + this.category_list[i] + '.json',
                        dataType: 'json',
                        success: (json)=>{
                            this.data_array.push(json.reverse());

                            // 全て読み終わったら実行
                            if (this.data_array.length == this.category_list.length) {
                                sortData();
                            }
                        },
                        error: ()=>{
                            console.log('GET JSON ERROR!');
                        }
                    });
                }
            } else {
                $.ajax({
                    type: 'GET',
                    url: '/assets/json/list_' + this.category + '.json',
                    dataType: 'json',
                    success: (json)=>{
                        this.data = json;
                        sortData();
                    },
                    error: ()=>{
                        console.log('GET JSON ERROR!');
                    }
                });
            }
        };

        judgeCategory();
        setJson();
    }
}
