export default class {
    constructor() {
        this.$el = $('.js-inview');
        this.speed_b = 2;

        this.dev = true;
    }
    init(){
        if (!this.dev) { return }

        this.setContents();
        this.set_inviewEvent();
    }
    setContents(){
        if (this.$el.length == 0) { return }

        this.$el
            .each((i, el)=>{
                // var b = $(el).data('view-b');
                var b = 30;

                $(el).css({ 'opacity': 0, 'transform': 'translateY(' + b + 'px)' })
            });
    }
    set_inviewEvent(){
        this.$el
            .on('inview', (e)=>{
                var $el = $(e.currentTarget),
                    d = 0;

                TweenMax.to($el, this.speed_b, {
                    ease: Power4.easeOut,
                    'transform': 'translateY(0)',
                    opacity: 1,
                    delay: d
                });
            });
    }
}
