import Slider from '../_modules/_gallery/slider';

export default class {
    constructor() {
    }
    init(){
        if (!$('body').hasClass('pageGallery')) { return };

        this.slider = new Slider();
        this.slider.init();
    }
}
