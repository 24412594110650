export default class {
    constructor() {
        this.$banner = $('._banner');
        this.$bannerClose = $('._banner_close');

        this.token = '8574185780.34d04fe.a6a5c037473c4db1a31f66c94decff18';
        this.userID = '8574185780';
    }
    init(){
        this.setEvent();
        this.setMediaSlider();
        this.setChannelSlider();
        this.setLinkSlider();
    }
    setJsonElem($el, json, $append){
        $el.tmpl(json).appendTo($append);
    }
    setEvent(){
        $.ajax({
            type: 'GET',
            url: '/assets/json/home_slide.json',
            dataType: 'json',
            success: (json)=>{
                let data = json.reverse();
                this.setJsonElem($('#tmpl_bg'), data, '#tmpl_bg-dist');
                //this.setJsonElem($('#tmpl_info'), data, '#tmpl_info-dist');
                this.setSlideer();
            },
            error: ()=>{
                console.log('GET JSON ERROR!');
            }
        });


        $.ajax({
            type: 'GET',
            url: '/assets/json/list_pickup.json',
            dataType: 'json',
            success: (json)=>{
                let data = json.reverse();
                this.setJsonElem($('#tmpl_panelListPickup'), data, '#tmpl_panelListPickup-dist');
                this.setPickupSlider();
            },
            error: ()=>{
                console.log('GET JSON ERROR!');
            }
        });


        /*
        $.ajax({
            type: 'GET',
            url: '/assets/json/news.json',
            dataType: 'json',
            success: (json)=>{
                let data = json.reverse();
                this.setNews(data);
            },
            error: ()=>{
                console.log('GET JSON ERROR!');
            }
        });
        */

        $.ajax({
            type: 'GET',
            url: '/assets/json/news.json',
            dataType: 'json',
            success: (json)=>{
                let data = json.reverse();
                this.setNews2(data);
            },
            error: ()=>{
                console.log('GET JSON ERROR!');
            }
        });

        $.ajax({
            url: 'https://api.instagram.com/v1/users/' + this.userID + '/media/recent',
            data: { access_token: this.token },
            dataType: 'jsonp',
            error: (jqXHR, textStatus, errorThrown)=>{
                console.log('error');
            },
            success: (data, textStatus, jqXHR)=>{
                let $list = $('._insta_list');
                let d = data.data;
                let max = 4;
                let link;
                let imgSrc;

                if ($list.length == 0) { return };

                for (var i = 0; i < max; i++) {
                    link = d[i].link;
                    imgSrc = d[i].images.low_resolution.url;

                    $list.append('<div class="_insta_item"><a href="' + link + ' " target="_blank"><img src="' + imgSrc + '" /></div>');
                }
            }
        });

        this.$bannerClose.on('click', ()=>{
            this.$banner.css({ 'display': 'none' });
            return false;
        });

        let banner_flag;
        let banner_flag2;
        let positionBanner = ()=>{
            let top = 90;
            let header_h = $('.header').outerHeight();
            let max = $('body').outerHeight() - app.size.winH;
            let adjust = 800;

            if (app.size.winW > app.size.breakPoint) {
                top = 126;
                header_h = 0;
                adjust = 800;
            }

            let point = top - header_h;

            if (this.scrollH < (max - adjust) && banner_flag != 'absolute') {
                banner_flag = 'absolute';
                TweenMax.to(this.$banner, .3, {
                    ease: Power0.easeNone,
                    'opacity': 1
                });
            } else if (this.scrollH >= (max - adjust) && banner_flag != 'delete') {
                banner_flag = 'delete';
                TweenMax.to(this.$banner, .3, {
                    ease: Power0.easeNone,
                    'opacity': 0
                });
            }

            if (this.scrollH < top && banner_flag2 != 'top') {
                banner_flag2 = 'top';
                TweenMax.to(this.$banner, .3, {
                    ease: Power0.easeNone,
                    'opacity': 0
                });
            } else if (this.scrollH >= top && banner_flag2 != 'scroll') {
                banner_flag2 = 'scroll';
                TweenMax.to(this.$banner, .3, {
                    ease: Power0.easeNone,
                    'opacity': 1
                });
            }
        }

        $(window).on('load scroll resize', ()=>{
            this.scrollH = $(window).scrollTop();
            positionBanner();
        });
    }
    setSlideer() {
        /*
        $('._visual_bg').slick({
            autoplay: true,
            speed: 600,
            autoplaySpeed: 6000,
            adaptiveHeight: true,
            dots: true,
            centerMode: true,
            centerPadding: "10%",
            prevArrow: '<button class="slick-prev slick-arrow" aria-label="Previous" type="button"><i class="icon-arrow02_l"></i></button>',
            nextArrow: '<button class="slick-next slick-arrow" aria-label="Next" type="button"><i class="icon-arrow02_r"></i></button>',
            responsive: [
              {
                breakpoint: 768, //399px以下のサイズに適用
                settings: {
                  centerMode: false,
                }
              },
            ],
        });
        */
        /*
        $('._visual_info').slick({
            autoplay: true,
            speed: 600,
            autoplaySpeed: 6000,
            dots: false,
            fade: true,
            arrows: false,
            adaptiveHeight: true,
            centerMode: true,
            centerPadding: "10%"
        });
        */
    }
    setMediaSlider(){
        $('._media_list').slick({
            autoplay: true,
            speed: 600,
            slidesToShow: 2,
            autoplaySpeed: 6000,
            prevArrow: '<button class="slick-prev slick-arrow" aria-label="Previous" type="button"><i class="icon-arrow02_l"></i></button>',
            nextArrow: '<button class="slick-next slick-arrow" aria-label="Next" type="button"><i class="icon-arrow02_r"></i></button>',
            responsive: [
                {
                    breakpoint: 769,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        });
    }
    setPickupSlider(){
        $('.panelListPickup_list').slick({
            autoplay: true,
            speed: 600,
            slidesToShow: 2,
            dots: true,
            autoplaySpeed: 6000,
            prevArrow: '<button class="slick-prev slick-arrow" aria-label="Previous" type="button"><i class="icon-arrow02_l"></i></button>',
            nextArrow: '<button class="slick-next slick-arrow" aria-label="Next" type="button"><i class="icon-arrow02_r"></i></button>',
            responsive: [
                {
                    breakpoint: 769,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        });


    }
    setChannelSlider(){
        $('.panelListChannel_list').slick({
            autoplay: true,
            speed: 600,
            slidesToShow: 3,
            dots: true,
            autoplaySpeed: 6000,
            centerMode: true,
            centerPadding: "2%",
            prevArrow: '<button class="slick-prev slick-arrow" aria-label="Previous" type="button"><i class="icon-arrow02_l"></i></button>',
            nextArrow: '<button class="slick-next slick-arrow" aria-label="Next" type="button"><i class="icon-arrow02_r"></i></button>',
            responsive: [
                {
                    breakpoint: 769,
                    settings: {
                        slidesToShow: 1,
                        centerMode: true,
                        centerPadding: "5%"
                    }
                }
            ]
        });


    }
    setLinkSlider(){
        $('.panelListLink_list').slick({
            autoplay: true,
            speed: 600,
            slidesToShow: 3,
            dots: true,
            autoplaySpeed: 6000,
            centerMode: true,
            centerPadding: "2%",
            prevArrow: '<button class="slick-prev slick-arrow" aria-label="Previous" type="button"><i class="icon-arrow02_l"></i></button>',
            nextArrow: '<button class="slick-next slick-arrow" aria-label="Next" type="button"><i class="icon-arrow02_r"></i></button>',
            responsive: [
                {
                    breakpoint: 769,
                    settings: {
                        slidesToShow: 1,
                        centerMode: true,
                        centerPadding: "5%"
                    }
                }
            ]
        });


    }
    /*
    setNews(json){
        let NEWS_NUM = 5;
        let newsArray = [];

        for (var i = 0; i < NEWS_NUM; i++) {
            newsArray.push(json[i]);
        }

        this.setJsonElem($('#tmpl_news_ticker'), newsArray, '#tmpl_news_ticker-dist');



        let $news = $('._news');
        let $newsWindow = $('._news_window');
        let $newsList = $('._news_list');
        let $newsItem = $('._news_item');
        let windowW = 0;
        let padding = 30;

        let counter = 0;
        let move = 0;
        let moving;

        $news.css({ 'opacity': 0 });
        $newsItem.eq(0).clone().appendTo($newsList);

        let newsInit = ()=>{
            windowW = 0;
            counter = 0;

            TweenMax.to($newsList, .01, {
                ease: Power0.easeNone,
                x: 0
            });

            $news.css({ 'opacity': 0 });
            $newsList.css({ 'width': 2000 });
            $newsList.find('._news_item').css({
                'width': 2000,
                // 'display': 'block',
                'float': 'none'
            });

            let setting = ()=>{
                $newsList.find('._news_item').each((i, el)=>{
                    let w = $(el).find('._news_inner').outerWidth();

                    if (w > windowW) {
                        windowW = w;
                    }
                });
                $newsList.find('._news_item').each((i, el)=>{
                    let w = $(el).find('._news_inner').outerWidth();

                    $(el).css({
                        'width': windowW + padding
                    })
                });

                $newsWindow.css({ 'width': windowW });
                $newsList.css({ 'width': (windowW + padding) * (NEWS_NUM + 1) + 100 });
                $newsList.find('._news_item').css({
                    // 'display': 'inline-block',
                    'float': 'left'
                });
                $news.css({ 'opacity': 1 });
            };

            setting();
        };

        let startTicker = ()=>{
            moving = setInterval(()=>{
                counter++;
                if (counter > NEWS_NUM) {
                    counter = 1;
                }
                move = (windowW + padding) * counter;

                TweenMax.to($newsList, 3, {
                    ease: Power0.easeNone,
                    x: -move,
                    onComplete: ()=>{
                        if (counter == NEWS_NUM) {
                            TweenMax.to($newsList, .01, {
                                ease: Power0.easeNone,
                                x: 0
                            });
                        }
                    }
                });
            } , 6000);
        }

        let stopTicker = ()=>{
            clearInterval(moving);
        }

        newsInit();
        startTicker();

        app.switcher.$obj
            .on('SWITCH_PC', ()=>{
                stopTicker();
                newsInit();
                startTicker();
            });

        app.switcher.$obj
            .on('SWITCH_SP', ()=>{
                stopTicker();
                newsInit();
                startTicker();
            });
    }
    */


    setNews2(json){
        let NEWS_NUM = 5;
        let newsArray = [];

        for (var i = 0; i < NEWS_NUM; i++) {
            newsArray.push(json[i]);
        }

        this.setJsonElem($('#tmpl_news'), newsArray, '#tmpl_news-dist');

    }
}
