export default class {
    constructor() {
        this.$slider = $('._photo_slide_list');
    }
    init(){
        this.setEvent();
    }
    setEvent(){
        this.$slider.slick({
            autoplay: false,
            autoplaySpeed: 4000,
            dots: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            responsive: [
                {
                  breakpoint: 768,
                  settings: {
                    autoplay: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    swipe: true,
                    dots: false
                  }
                }
            ]
        });
    }
}
