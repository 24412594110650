import Modal from '../../_modules/_common/modal';

export default class {
    constructor() {
        this.index = 0;
        this.devModal = false;
    }
    init(){
        this.setEvent();
    }
    setEvent(){

        this.memberModal = new Modal({
            type: 'default',
            triggerOpenClass: 'js-modalOpen',
            triggerCloseClass: 'modalClose',
            modalTargetID: 'member-modal',
            openingEvent: ()=>{
                $('.remodal-wrapper').scrollTop(0);
                $('._memberModal_list').slick({
                    initialSlide: this.index,
                    prevArrow: '<p class="prev"><img src="/assets/img/about/prev_arrow.svg"></i></p>',
                    nextArrow: '<p class="next"><img src="/assets/img/about/next_arrow.svg"></i></p>'
                });
            },
            closedEvent: ()=>{
                $('._memberModal_list').slick('unslick');
            }
        });
        this.memberModal.init();

        $('._member_item a').on('click', (e)=>{
            let $el = $(e.currentTarget);
            let index = $el.attr('href');

            this.index = Number(index);

            this.memberModal.openModal();
            return false;
        });


        app.switcher.$obj
            .on('SWITCH_PC', ()=>{
                if ($('._member_list').hasClass('slick-initialized')) {
                    $('._member_list').slick('unslick');
                }
            });

        app.switcher.$obj
            .on('SWITCH_SP', ()=>{
                $('._member_list').slick({
                    dots: true,
                    prevArrow: '<p class="prev"><img src="/assets/img/about/prev_arrow.svg"></i></p>',
                    nextArrow: '<p class="next"><img src="/assets/img/about/next_arrow.svg"></i></p>'
                });
            });


        if (this.devModal) {
            this.memberModal.openModal();
        }
    }
}
