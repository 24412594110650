export default class {
    constructor() {
        this.$btn = $('.js-scroll');
        this.speed = .5;
    }
    init(){
        this.setEvent();
    }
    setEvent(){
        this.$btn
            .on('click',(e)=>{
                this.href = $(e.currentTarget).attr('href');
                this.target = $(this.href == "#" || this.href == "" ? 'html' : this.href);
                this.position = this.target.offset().top;

                TweenMax.to('body,html', this.speed, {
                    ease: Power3.easeOut,
                    scrollTop:this.position
                });

                return false;
            })
    }
}
