export default class {
    constructor() {
        this.$slider = $('.article__slide');
    }
    init(){
        this.setEvent();
    }
    setEvent() {
        $(function() {
            $(".article__slide").each(function() {
                const $slider = $(this); // 現在のスライダーを変数に保存
                $slider
                    .on("init", function(event, slick) {
                        // スライダー毎にカウンタを追加
                        $slider.append(
                            '<div class="slider-num"><span class="now-count"></span>　/　<span class="all-count"></span></div>'
                        );
                        $slider.find(".now-count").text(slick.currentSlide + 1);
                        $slider.find(".all-count").text(slick.slideCount);
                    })
                    .slick({
                        autoplay: false,
                        prevArrow: '<img src="/assets/img/common/article/arrow_prev.svg" class="slide-arrow prev-arrow">',
                        nextArrow: '<img src="/assets/img/common/article/arrow_next.svg" class="slide-arrow next-arrow">'
                    })
                    .on("beforeChange", function(event, slick, currentSlide, nextSlide) {
                        // スライダー内のカウンタのみを更新
                        $slider.find(".now-count").text(nextSlide + 1);
                    });
            });
        });
    }
}
