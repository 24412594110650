export default class {
    constructor() {
        this.$panelList = $('.panelListWorkshop');
        this.$dist = $('#tmpl_panelListWorkshop');

        this.category_list = ['workshop'];
        this.data_array = [];

        this.category;

        this.data = [];

        this.SHOW_ITEMS = 3;
    }
    init(){
        if (this.$panelList.length == 0) { return };

        this.setJson();
    }
    setJson(){
        let judgeCategory = ()=>{
            let data = this.$panelList.attr('data-category');

            if (data == 'panel-all') {
                this.category = 'all';
            } else if (data == 'panel-data') {
                this.category = 'data';
            } else if (data == 'panel-special') {
                this.category = 'special';
            } else if (data == 'panel-workshop') {
                this.category = 'workshop';
            } else if (data == 'panel-event') {
                this.category = 'event';
            } else if (data == 'panel-pickup') {
                this.category = 'pickup';
            } else if (data == 'panel-other') {
                this.category = 'other';
            }
        };

        let sortData = ()=>{
            let fixData;

            if (this.category == 'all') {
                for (var i = 0; i < this.data_array.length; i++) {
                    for (var j = 0; j < this.data_array[i].length; j++) {
                        this.data.push(this.data_array[i][j]);
                    }
                }

                this.data.sort((a,b)=>{
                    return (a.date < b.date ? 1 : -1);
                });
            } else {
                this.data.reverse();
            }

            //表示すべきデータをセットしてからイベントスタート
            this.setEvent();
        };

        let setJson = ()=>{
            if (this.category == 'all') {
                for (var i = 0; i < this.category_list.length; i++) {
                    $.ajax({
                        type: 'GET',
                        url: '/assets/json/list_' + this.category_list[i] + '.json',
                        dataType: 'json',
                        success: (json)=>{
                            this.data_array.push(json.reverse());

                            // 全て読み終わったら実行
                            if (this.data_array.length == this.category_list.length) {
                                sortData();
                            }
                        },
                        error: ()=>{
                            console.log('GET JSON ERROR!');
                        }
                    });
                }
            } else {
                $.ajax({
                    type: 'GET',
                    url: '/assets/json/list_' + this.category + '.json',
                    dataType: 'json',
                    success: (json)=>{
                        this.data = json;
                        sortData();
                    },
                    error: ()=>{
                        console.log('GET JSON ERROR!');
                    }
                });
            }
        };

        judgeCategory();
        setJson();
    }
    setEvent(){
        this.LENGTH = this.data.length;
        this.$dist.tmpl(this.data).appendTo("#tmpl_panelListWorkshop-dist");

        // this.$panelList.find('.panelList_item')
        //     .each((i, el)=>{
        //         var b = 30;
        //
        //         $(el).css({ 'opacity': 0, 'transform': 'translateY(' + b + 'px)' })
        //     });
        //
        // this.$panelList.find('.panelList_item')
        //     .on('inview', (e)=>{
        //         var $el = $(e.currentTarget),
        //             d = 0;
        //
        //         TweenMax.to($el, 2, {
        //             ease: Power4.easeOut,
        //             'transform': 'translateY(0)',
        //             opacity: 1,
        //             delay: d
        //         });
        //     });

        // 順番変更用のタイムを削除
        $('.panelList_date').each((i, el)=>{
            let $el = $(el);
            let text = $el.context.innerText;

            text = text.substring(10, -1);
            $el.html(text);
        });

        if (this.LENGTH > 3) {
            this.setPager();
        }
    }
    setPager(){
        let pagenation = ()=>{
            let $pager = '<ul class="sync-pagination panelList_pager" style="display: none;"></ul>';

            this.$panelList.append($pager);

            let totalPages = Math.ceil(this.LENGTH / this.SHOW_ITEMS);
            let hashPage = Number(location.hash.replace('#page-', ''));

            if (hashPage > 0 && hashPage <= totalPages) {
                this.currentPage = hashPage;
            }

            $('.sync-pagination').twbsPagination({
                startPage: this.currentPage,
                prev: '<i class="icon-arrow02_l"></i>',
                next: '<i class="icon-arrow02_r"></i>',
                totalPages: totalPages,
                onPageClick: (e, page)=>{
                    this.currentPage = page;
                    location.hash = 'page-' + page;
                    this.loaded = true;
                    /*
                    if (this.loaded) {
                        TweenMax.to('body,html', .8, {
                            ease: Power3.easeOut,
                            scrollTop: this.$panelList.offset().top - 100
                        });
                    } else {
                        this.loaded = true;
                    }
                    */
                }
            });
        };

        pagenation();


        let switchItems = (startNum, lastNum)=>{
            this.$panelList.find('.panelList_item').each((i, el)=>{
                if ((i + 1) >= startNum && (i + 1) <= lastNum) {
                    $(el).css({ 'display': 'inline-block' });
                } else {
                    $(el).css({ 'display': 'none' });
                }
            });
        };


        $(window).on('hashchange', ()=>{
            let start = (this.currentPage - 1) * this.SHOW_ITEMS + 1;
            let last = this.currentPage * this.SHOW_ITEMS;
            switchItems(start, last);
        });


        $(window).on('load', ()=>{


            let start = (this.currentPage - 1) * this.SHOW_ITEMS + 1;
            let last = this.currentPage * this.SHOW_ITEMS;
            switchItems(start, last);
        });
    }
}
