export default class {
    constructor() {
    }
    init(){
        this.setEvent();
    }
    setEvent(){
        $.ajax({
            type: 'GET',
            url: '/assets/json/release.json',
            dataType: 'json',
            success: (json)=>{
                let data = json.reverse();
                this.setNews(data);
            },
            error: ()=>{
                console.log('GET JSON ERROR!');
            }
        });
    }
    setJsonElem($el, json, $append){
        $el.tmpl(json).appendTo($append);
    }
    setNews(json){
        this.setJsonElem($('#tmpl_release'), json, '#tmpl_release-dist');
    }
}
