import Slider from '../_modules/_special/slider';

export default class {
    constructor() {
    }
    init(){
        if (!$('body').hasClass('-thanksfair-report')) { return };

        this.slider = new Slider();
        this.slider.init();
    }
}
