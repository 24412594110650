export default class {
    constructor() {
        this.breakPoint = 768;

        this.winW = $(window).width();
        $(window).on('load resize',()=>{this.winW = $(window).width();})

        // this.winH = $(window).height();
        this.winH = window.innerHeight;
        $(window).on('load resize',()=>{this.winH = window.innerHeight;})
    }
}
