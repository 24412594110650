import Modal from '../../_modules/_common/modal';

export default class {
    constructor() {
        this.index = 0;
        this.devModal = false;
    }
    init(){
        this.setEvent();
    }
    setEvent(){

        this.diagnosisModal = new Modal({
            type: 'default',
            triggerOpenClass: 'js-modalOpen',
            triggerCloseClass: 'modalClose',
            modalTargetID: 'diagnosis-modal',
        });
        this.diagnosisModal.init();

        $('.qa a').on('click', (e)=>{
            let $el = $(e.currentTarget);
            let index = $el.attr('href');

            this.index = Number(index);

            this.diagnosisModal.openModal();
            return false;
        });

        $('.type_link_anchor_red').on('click', (e)=>{
            //ページ内スクロール
            var p = $("#type_red").offset().top-70;
            $('html,body').animate({ scrollTop: p }, 'fast');

            this.diagnosisModal.closeModal();
            return false;
        });

        $('.type_link_anchor_blue').on('click', (e)=>{
            //ページ内スクロール
            var p = $("#type_blue").offset().top-70;
            $('html,body').animate({ scrollTop: p }, 'slow');

            this.diagnosisModal.closeModal();
            return false;
        });

        $('.type_link_anchor_green').on('click', (e)=>{
            //ページ内スクロール
            var p = $("#type_green").offset().top-70;
            $('html,body').animate({ scrollTop: p }, 'slow');

            this.diagnosisModal.closeModal();
            return false;
        });

        $('.type_link_anchor_yellow').on('click', (e)=>{
            //ページ内スクロール
            var p = $("#type_yellow").offset().top-70;
            $('html,body').animate({ scrollTop: p }, 'slow');

            this.diagnosisModal.closeModal();
            return false;
        });

        $('.type_link_anchor_pink').on('click', (e)=>{
            //ページ内スクロール
            var p = $("#type_pink").offset().top-70;
            $('html,body').animate({ scrollTop: p }, 'slow');

            this.diagnosisModal.closeModal();
            return false;
        });

        if (this.devModal) {
            this.diagnosisModal.openModal();
        }
    }


}

$(function(){
    $(".diagnosis_a_item .btn").on("click", function() {
       $(this).closest("div").css("display","none");
       var id = $(this).attr("href");
       $(id).addClass("positionFit").show("fast");
    });
});
