export default class {
    constructor() {
        this.$slider = $('._photo_slide_list');
    }
    init(){
        this.setEvent();
    }
    setEvent(){
        this.$slider.slick({
            autoplay: true,
            autoplaySpeed: 4000,
            dots: true
        });
    }
}
