export default class {
    constructor() {
        this.$replaceImg = $('.js_imgReplace');
    }
    init(){
        this.setEvent();
    }
    setEvent(){
        app.switcher.$obj
            .on('SWITCH_PC', ()=>{
                this.$replaceImg.each((i, el)=>{
                    $(el).attr('src', $(el).attr('src').replace('_sp', ''));
                });
            });

        app.switcher.$obj
            .on('SWITCH_SP', ()=>{
                this.$replaceImg.each((i, el)=>{
                    $(el).attr('src', $(el).attr('src').replace(/(\.gif|\.jpg|\.png)/g, '_sp$1'));
                });
            });
    }
}
