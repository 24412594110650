import PanelList from '../_modules/_common/panelList';
import PanelListEvent from '../_modules/_common/panelListEvent';
import PanelListData from '../_modules/_common/panelListData';
import PanelListWorkshop from '../_modules/_common/panelListWorkshop';
import PanelListSpecial from '../_modules/_common/panelListSpecial';
import PanelListOther from '../_modules/_common/panelListOther';
import PanelListPickup from '../_modules/_common/panelListPickup';
import Reccomend from '../_modules/_common/reccomend';
import Ranking from '../_modules/_common/ranking';
import Inview from '../_modules/_common/inview';
import ReplaceImg from '../_modules/_common/replaceImg';
import Nav from '../_modules/_common/nav';
import Scroll from '../_modules/_common/scroll';
import Pagetotop from '../_modules/_common/pagetotop';
import HeaderNav from '../_modules/_common/header';
import ArticleSlider from '../_modules/_common/articleSlider';

export default class {
    constructor() {
    }
    init(){
        this.panelList = new PanelList();
        this.panelList.init();

        this.panelListData = new PanelListData();
        this.panelListData.init();

        this.panelListWorkshop = new PanelListWorkshop();
        this.panelListWorkshop.init();

        this.panelListSpecial = new PanelListSpecial();
        this.panelListSpecial.init();

        this.panelListOther = new PanelListOther();
        this.panelListOther.init();

        this.panelListEvent = new PanelListEvent();
        this.panelListEvent.init();

        this.panelListPickup = new PanelListPickup();
        this.panelListPickup.init();

        this.reccomend = new Reccomend();
        this.reccomend.init();

        this.ranking = new Ranking();
        this.ranking.init();

        // this.inview = new Inview();
        // this.inview.init();

        this.replaceImg = new ReplaceImg();
        this.replaceImg.init();

        this.nav = new Nav();
        this.nav.init();

        this.scroll = new Scroll();
        this.scroll.init();

        this.pagetotop = new Pagetotop();
        this.pagetotop.init();

        this.headernav = new HeaderNav();
        this.headernav.init();

        this.articleSlider = new ArticleSlider();
        this.articleSlider.init();
    }
}
