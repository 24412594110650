export default class {
    constructor() {
        this.$slider = $('._photo_slide_list');
        this.$thumbnail = $('._thumbnail_list');
    }
    init(){
        this.setEvent();
    }
    setEvent(){
        this.$slider.slick({
            asNavFor: '._thumbnail_list',
            pauseOnFocus: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            fade: true
        });
        this.$thumbnail.slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            asNavFor: '._photo_slide_list',
            dots: false,
            arrows: false,
            centerMode: true,
            focusOnSelect: true
        });
    }
}
