export default class {
    constructor() {
        this.$header = $('.header');
        this.$headerBtn = $('.header_btn');
        this.$navNosub = $('a.-noSub');
        this.$navBtn = $('.nav_btn');
        this.$subNavLinkAbout = $('.nav_about');
        this.$subNavLinkLab = $('.nav_lab');
        this.$subNavLinkGallery = $('.nav_gallery');
        this.$subNavLinkAmb = $('.nav_ambassador');
        this.$subNavLinkCov = $('.header_nav_item');

        this.$subNavLinkAboutSide = $('.nav_head_about');
        this.$subNavLinkLabSide = $('.nav_head_lab');
        this.$subNavLinkGallerySide = $('.nav_head_gallery');
        this.$subNavLinkAmbSide = $('.nav_head_ambassador');

        this.mousewheelevent = 'onwheel' in document ? 'wheel' : 'onmousewheel' in document ? 'mousewheel' : 'DOMMouseScroll';

        this.devMove = false;
    }
    init(){
        this.setEvent();
    }
    setEvent(){

        this.setNavPosition();

        $(window).on('resize', ()=>{
            this.setNavPosition();
        });

        this.$headerBtn.on('click', ()=>{

            if ($('body').hasClass('-navOpen')) {
                this.closeNav();
            } else {
                this.openNav();
            }

            return false;
        });

        this.$navNosub.on('click', ()=>{
            if ($('body').hasClass('-navOpen')) {
                this.closeNav();
            } else {
                this.openNav();
            }
        });

        this.$navBtn.on('click', ()=>{
            this.closeNav();

            return false;
        });

        if (this.devMove) {
            this.openNav();
        }

        this.$subNavLinkAbout.on('mouseenter', ()=>{
            this.openSubNavAbout();
            return false;
        });
        this.$subNavLinkLab.on('mouseenter', ()=>{
            this.openSubNavLab();
            return false;
        });
        this.$subNavLinkAmb.on('mouseenter', ()=>{
            this.openSubNavAmb();

            return false;
        });
        this.$subNavLinkGallery.on('mouseenter', ()=>{
            this.openSubNavGallery();

            return false;
        });
        this.$subNavLinkCov.on('mouseleave', ()=>{
            this.closeSubNav();

            return false;
        });

        if (this.devMove) {
            this.openSubNav();
        }

        this.$subNavLinkAboutSide.on('click', ()=>{
            if ($('.nav_list_about').hasClass('-subNavOpen')) {
                this.closeSubNavSide();
            } else {
                this.openSubNavAboutSide();
            }

            return false;
        });

        this.$subNavLinkLabSide.on('click', ()=>{
            if ($('.nav_list_lab').hasClass('-subNavOpen')) {
                this.closeSubNavSide();
            } else {
                this.openSubNavLabSide();
            }

            return false;
        });

        this.$subNavLinkAmbSide.on('click', ()=>{
            if ($('.nav_list_ambassador').hasClass('-subNavOpen')) {
                this.closeSubNavSide();
            } else {
                this.openSubNavAmbSide();
            }
            return false;
        });

        this.$subNavLinkGallerySide.on('click', ()=>{
            if ($('.nav_list_gallery').hasClass('-subNavOpen')) {
                this.closeSubNavSide();
            } else {
                this.openSubNavGallerySide();
            }

            return false;
        });
    }
    setNavPosition(){
        let $navInner = $('.nav_inner');
        let $navConts = $('.nav_conts');
        let areaH = $navInner.outerHeight();
        let contsH = $navConts.outerHeight();


        if (contsH < areaH) {
            $navConts.css({
                'margin-top': (areaH - contsH) / 2
            })
        } else {
            $navConts.css({
                'margin-top': 40
            })
        }

        if (app.size.winW <= app.size.breakPoint) {
            $navConts.css({
                'margin-top': 40
            })
        }
    }
    openNav(){
        $('body').addClass('-navOpen');
    }
    closeNav(){
        $('body').removeClass('-navOpen');
    }

    openSubNavLab(){
        $('.subnav_inner_lab').addClass('-subNavOpen');
    }
    openSubNavAbout(){
        $('.subnav_inner_about').addClass('-subNavOpen');
    }
    closeSubNav(){
        $('.header_subnav_inner').removeClass('-subNavOpen');
    }
    openSubNavGallery(){
        $('.subnav_inner_gallery').addClass('-subNavOpen');
    }
    openSubNavAmb(){
        $('.subnav_inner_ambassador').addClass('-subNavOpen');
    }

    openSubNavAboutSide(){
        $('.nav_list_about').addClass('-subNavOpen');
        $('.nav_head_about').addClass('-subNavOpen');
    }
    openSubNavLabSide(){
        $('.nav_list_lab').addClass('-subNavOpen');
        $('.nav_head_lab').addClass('-subNavOpen');
    }
    closeSubNavSide(){
        $('.nav_list').removeClass('-subNavOpen');
        $('.nav_head').removeClass('-subNavOpen');
    }
    openSubNavGallerySide(){
        $('.nav_list_gallery').addClass('-subNavOpen');
        $('.nav_head_gallery').addClass('-subNavOpen');
    }
    openSubNavAmbSide(){
        $('.nav_list_ambassador').addClass('-subNavOpen');
        $('.nav_head_ambassador').addClass('-subNavOpen');
    }
}
