import Diagnosis from '../_modules/_diagnosis/diagnosis';

export default class {
    constructor() {
    }
    init(){
        if (!$('body').hasClass('-bachelor_02')) { return };

        this.about = new Diagnosis();
        this.about.init();
    }
}
