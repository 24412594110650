import Release from '../_modules/_release/release';

export default class {
    constructor() {
    }
    init(){
        if (!$('body').hasClass('pageRelease')) { return };

        this.release = new Release();
        this.release.init();
    }
}
