/*
 * ============================================
 * GLOBAL
 * ============================================
**/

//UTILITY
import Env from './_app/class-utility/Env';

//HELPER
import Size from './_app/helper/size';
import Switcher from './_app/helper/switcher';

class App extends TinyEmitter{
    constructor() {
        super();

        //UTILITY
        this.env = new Env();
        this.size = new Size();

        this.$app = $('#app');
    }
    start(){
        this.emit('START_BEFORE');

        this.switcher = new Switcher();
        this.switcher.init();

        $(()=>{
            this._startpage();
            this.emit('START_AFTER');
        });
    }
    _startpage(){
        this.page = new this.page();
        this.page.init();
    }
}

window.app = new App();



/*
 * ============================================
 * APP
 * ============================================
**/

import Common from './_controllers/controller_common';
import Home from './_controllers/controller_home';
import News from './_controllers/controller_news';
import About from './_controllers/controller_about';
import Gallery from './_controllers/controller_gallery';
import Event from './_controllers/controller_event_info';
import Diagnosis from './_controllers/controller_diagnosis';
import Release from './_controllers/controller_release';
import Special from './_controllers/controller_special';

class Page extends Common{
    constructor() {
        super();
    }
    init(){
        super.init();

        this.home = new Home();
        this.home.init();

        this.news = new News();
        this.news.init();

        this.about = new About();
        this.about.init();

        this.gallery = new Gallery();
        this.gallery.init();

        this.gallery = new Event();
        this.gallery.init();

        this.gallery = new Diagnosis();
        this.gallery.init();

        this.release = new Release();
        this.release.init();

        this.gallery = new Special();
        this.gallery.init();
    }
}

app.page = Page;
app.start();
