export default class {
    constructor() {
    }
    init(){
        this.setEvent();
    }
    setEvent(){
        $.ajax({
            type: 'GET',
            url: '/assets/json/reccomend.json',
            dataType: 'json',
            success: (json)=>{
                let data = json.reverse();
                this.setReccomend(data);
            },
            error: ()=>{
                console.log('GET JSON ERROR!');
            }
        });
    }
    setJsonElem($el, json, $append){
        $el.tmpl(json).appendTo($append);
    }
    setReccomend(json){
        this.setJsonElem($('#tmpl_reccomend'), json, '#tmpl_reccomend-dist');
    }
}
