import About from '../_modules/_about/about';
import Slider from '../_modules/_about/slider';

export default class {
    constructor() {
    }
    init(){
        if (!$('body').hasClass('pageAbout') && !$('body').hasClass('pageCompany') && !$('body').hasClass('pageMailMag')) { return };

        this.about = new About();
        this.about.init();

        if (!$('body').hasClass('pageCompany')) { return };
        this.slider = new Slider();
        this.sliderLink = new Slider();
        this.sliderLink.init();
    }
}
