import News from '../_modules/_news/news';

export default class {
    constructor() {
    }
    init(){
        if (!$('body').hasClass('pageNews')) { return };

        this.news = new News();
        this.news.init();
    }
}
