export default class {
    constructor() {
        this.$slider = $('._photo_slide_list');
        this.$sliderLink = $('.panelListLink_list');
    }
    init(){
        this.setEvent();
    }
    setEvent(){
        this.$slider.slick({
            autoplay: true,
            autoplaySpeed: 4000,
            dots: true,
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: true,
            responsive: [
                {
                  breakpoint: 768,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true
                  }
                }
            ]
        });
        this.$sliderLink.slick({
            autoplay: true,
            speed: 600,
            slidesToShow: 3,
            dots: true,
            autoplaySpeed: 6000,
            centerMode: true,
            prevArrow: '<button class="slick-prev slick-arrow" aria-label="Previous" type="button"><i class="icon-arrow02_l"></i></button>',
            nextArrow: '<button class="slick-next slick-arrow" aria-label="Next" type="button"><i class="icon-arrow02_r"></i></button>',
            responsive: [
                {
                    breakpoint: 769,
                    settings: {
                        slidesToShow: 1,
                        centerMode: true,
                        centerPadding: "5%"
                    }
                }
            ]
        });
    }
}
