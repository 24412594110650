export default class {
    constructor() {
    }
    init(){
        this.setEvent();
    }
    setEvent(){
        $.ajax({
            type: 'GET',
            url: '/assets/json/ranking.json',
            dataType: 'json',
            success: (json)=>{
                let data = json.reverse();
                this.setRanking(data);
            },
            error: ()=>{
                console.log('GET JSON ERROR!');
            }
        });
    }
    setJsonElem($el, json, $append){
        $el.tmpl(json).appendTo($append);
    }
    setRanking(json){
        this.setJsonElem($('#tmpl_ranking'), json, '#tmpl_ranking-dist');
        // 順番変更用のタイムを削除
        $('.gadget_date').each((i, el)=>{
            let $el = $(el);
            let text = $el.context.innerText;
            console.log(text)

            text = text.substring(10, -1);
            $el.html(text);
        });
    }
}
