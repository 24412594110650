import Home from '../_modules/_home/home';
import Op from '../_modules/_home/opening';

export default class {
    constructor() {
    }
    init(){
        if (!$('body').hasClass('pageHome')) { return };

        this.home = new Home();
        this.home.init();

        this.op = new Op();
        this.op.init();
    }
}
